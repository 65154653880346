import { alertController } from "@ionic/vue";
import { defineStore } from "pinia";
import { Network } from "@capacitor/network";
import {
  DataStore,
  SortDirection,
  syncExpression,
} from "aws-amplify/datastore";
import {
  DevFittrOrder,
  DevFittrLast,
  DevFittrModel,
  DevFittrPriceLimit,
} from "@/models";
import { Capacitor } from "@capacitor/core";
import { fetchUserAttributes } from "aws-amplify/auth";

/* PENDERS */
/* import logo from "../assets/Logo-Penders-Voetzorg.svg"; */

/* KIEVIT */
/* import logo from "../assets/kievit_logo.webp"; */

/* FOOTCARE */
import logo from "../assets/logo.png";

/* FITTR */
/* import logo from "../assets/fittr_logo.png"; */

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      Lasts: DevFittrLast,
      Models: DevFittrModel,
      app_logo: logo,
      /* color_scheme: 'dark', */
      color_scheme: "light",
      logged_in_user: undefined,
      organisation: "fittr",
      priceFilter: true,
      category: "",
      lastImage: false,
      stiffnerImage: false,
      outsoleImage: false,
      signedIn: false,
      priceLimit: 0,
      brands: [],
      imageLoadingState: false,
      filterLoadingState: false,
      modelCollection: {},
      models: [],
      lastSizes: [],
      initialisedModels: [],
      expensiveModels: [],
      submittedOrders: [],
      archivedOrders: [],
      cheapModels: [],
      activeModel: {},
      activeOrder: {},
      footData: {},
      lastProposals: {},
      filters: {
        styles: [],
        height: [],
        closings: [],
        colors: [],
        priceLevel: [],
        healthcareProviders: [],
        suppliers: [],
        brand: [],
      },
      isOpenRef: false,
      selectedFilters: [],

      /* PENDERS */
        /* shareUrl: "https://app.pendersvoetzorg.nl/osb/models/",
				 redirectUrl: "https://app.pendersvoetzorg.nl",
				 url: "https://app.pendersvoetzorg.nl", */

      /* KIEVIT */
       /* shareUrl: "https://app.kievitorthopedie.nl/osb/models/",
				 redirectUrl: "https://app.kievitorthopedie.nl",
				 url: "https://app.kievitorthopedie.nl", */

      /* FITTR */
      /* shareUrl: "https://fittrapp.com/osb/models/",
      redirectUrl: "https://fittrapp.com",
      url: "https://fittrapp.com", */

      /* FOOTCARE */
      shareUrl: "https://footcaremaatschoenen.info/osb/models/",
				 redirectUrl: "https://footcaremaatschoenen.info",
				 url: "https://footcare.nl",

      imagePath: "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/",
      isOpen: false,
      favoritesOpen: false,
      scrollState: false,
      helpContent: "",
    };
  },
  actions: {
    $resetOrder() {
      this.activeOrder = {}
    },
    showAlert(message) {
      $q.notify({
        color: "red-5",
        position: "center",
        textColor: "white",
        icon: "fas fa-triangle-exclamation",
        message: message,
      });
    },
    setOpen(open) {
      this.isOpenRef = open;
      if (open === false) {
        this.favoritesOpen = false;
      }
    },
    findUniqueModelValue(column) {
      const length = this.initialisedModels.length;
      const result = new Set();
      for (let i = 0; i < length; i++) {
        let model = this.initialisedModels[i];
        if (column === "brand") {
          result.add(model[column]);
        } else {
          for (let j = 0; j < model.attributes?.[column]?.length; j++) {
            result.add(model.attributes?.[column][j]);
          }
        }
      }
      return [...result];
    },
    async filterModels(propName, it) {
      this.scrollState = true;

      const index = this.filters[propName].indexOf(it);

      if (index === -1) {
        this.filters[propName].push(it);
      } else {
        this.filters[propName].splice(index, 1);
      }

      let filteredModels = [...this.initialisedModels];

      const filterModels = async (field) => {
        if (this.filters[field]?.length > 0) {
          const processedFilenames = new Set();
          const list = this.filters[field].reduce((result, filterValue) => {
            filteredModels?.forEach((model) => {
              const filename = model.attributes?.filename || model.filename;
              if (
                !processedFilenames.has(filename) &&
                (model.attributes[field] || model[field])?.includes(filterValue)
              ) {
                result.add(model);
                processedFilenames.add(filename);
              }
            });
            return result;
          }, new Set());

          filteredModels = [...list];
        }
      };

      const filterFields = [
        "styles",
        "height",
        "closings",
        "colors",
        "brand",
        "priceLevel",
        "suppliers",
        "healthcareProviders",
      ];

      // Use Promise All instead of Await inside loop for better performance
      await Promise.all(
        filterFields
          .filter((field) => this.filters[field]?.length > 0)
          .map(filterModels)
      );

      this.models = filteredModels;

      return filteredModels.length;
    },
    async getPriceLimit(organisation) {
      try {
        const limit = await DataStore.query(DevFittrPriceLimit, (c) =>
          c.organisation.contains(organisation)
        );
        this.priceLimit = limit[0].amount;
        return limit[0].amount;
      } catch (e) {
        return "User not logged in!";
      }
    },
    async updatePriceLimit(user, price) {
      console.log(user);
      const limit = await DataStore.query(DevFittrPriceLimit, (c) =>
        c.organisation.contains(user["custom:organisation"].toString())
      );
      const original = limit[0];
      if (original) {
        const updatedPost = await DataStore.save(
          DevFittrPriceLimit.copyOf(original, (updated) => {
            updated.amount = price;
          })
        );
        this.priceLimit = price;
      }
    },
    async getSubmittedOrders() {
      const temp = await DataStore.query(
        DevFittrOrder,
        (c) =>
          c.and((c) => [
            c.organisation.eq(this.logged_in_user["custom:organisation"]),
            c.userName.eq(this.logged_in_user.email),
          ]),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
          page: 0,
          limit: 20,
        }
      );

      this.submittedOrders = temp.filter(
        (order) =>
          order.status.includes("submitted") ||
          order.status.includes("processed")
      );

      this.submittedOrders.push("last_item");
      return this.submittedOrders;
    },
    async getArchivedOrders() {
      this.archivedOrders = await DataStore.query(
        DevFittrOrder,
        (c) =>
          c.and((c) => [
            c.status.contains("archived"),
            c.userName.eq(this.logged_in_user.email),
          ]),
        {
          sort: (s) => s.updatedAt(SortDirection.DESCENDING),
        }
      );
      return this.archivedOrders;
    },
    async searchSubmittedOrders(value) {
      if (value === "") {
        await this.getSubmittedOrders();
      } else {
        const lowerCased = value.replaceAll(" ", "").toLowerCase();
        this.submittedOrders = await DataStore.query(
          DevFittrOrder,
          (c) =>
            c.and((c) => [
              c.search.contains(lowerCased),
              c.userName.eq(this.logged_in_user.email),
            ]),
          {
            sort: (s) => s.updatedAt(SortDirection.DESCENDING),
          }
        );
      }
    },
    async searchArchivedOrders(value) {
      const user = await fetchUserAttributes();
      if (value === "") {
        await this.getArchivedOrders();
      } else {
        const lowerCased = value.replaceAll(" ", "").toLowerCase();
        this.archivedOrders = await DataStore.query(
          DevFittrOrder,
          (c) =>
            c.and((c) => [
              c.search.contains(lowerCased),
              c.status.contains("archived"),
              c.userName.eq(this.logged_in_user.email),
            ]),
          {
            sort: (s) => s.updatedAt(SortDirection.DESCENDING),
          }
        );
      }
    },
    async dbSync() {
      console.log("setting Cloudsync data");
      fetchUserAttributes().then((user) => {
        DataStore.configure({
          syncExpressions: [
            syncExpression(DevFittrOrder, () => {
              return (order) =>
                order.organisation.eq(user["custom:organisation"]);
            }),
          ],
        });
      });
    },
    async showAlert(message) {
      const showAlert = (message) => {
        $q.notify({
          color: "red-5",
          position: "center",
          textColor: "white",
          icon: "fas fa-triangle-exclamation",
          message: message,
        });
      };
    },
    async initAppGlobals() {
      Network.addListener("networkStatusChange", async (status) => {
        console.log("Network status changed", status);
        const result = await Network.getStatus();
        console.log(result.connected);
        this.imagePath = result.connected
          ? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/"
          : Capacitor.convertFileSrc(window.location.origin + "/model_images/");
        console.log(this.imagePath);
      });
      // const result = await Network.getStatus();
      // this.imagePath = result.connected
      //   ? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/"
      //   : Capacitor.convertFileSrc(window.location.origin + "/model_images/");
    },
  },
});
